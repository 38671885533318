import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { FooterComp } from './Components/Footer';
import NavbarComp from './Components/Navbar';
import Section1 from './Homepage/Section1';
import Section2 from './Homepage/Section2';
import Section3 from './Homepage/Section3';
import Section4 from './Homepage/Section4';
import { Section5 } from './Homepage/Section5';
import Breadcrumbcomp from "./Components/Breadcrumbcomp";
import GalleryComp from "./Components/GalleryComp";

const Homepage = () => {
  return (
    <div className="App">
      <NavbarComp />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <FooterComp />
    </div>
  );
}
const Gallery = () => {
  return (
    <div className="App">
      <NavbarComp />
      <Breadcrumbcomp />
      <GalleryComp />
      <Section5 />
      <FooterComp />
    </div>
  );
}

export default function App() {
  return(
    <BrowserRouter>
      <Routes>
        <Route index element={<Homepage />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
    </BrowserRouter>
  );
}

