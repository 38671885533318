import { Card } from "flowbite-react";
import { Button } from "flowbite-react";
import Card1 from "../Assets/quad-sharing.webp"
import Card2 from "../Assets/double-sharing.webp"
import { Link } from "react-router-dom";

export default function Section4() {
    return(
        <>
        <div className="mt-12 sm:mt-24 text-start primary-color">
            <p className='text-4xl font-semibold text-white'>Our Rooms</p>
            <p className="text-base mt-4">Our all rooms are attached with bathrooms and private balcony, with magnificent view seen from the room itself. Floors and celling are made of pine trees wood which gives it a royal look.</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-12 gap-5">
            <Card
                className="max-w-xl"
                imgAlt="Apple Watch Series 7 in colors pink, silver, and black"
                imgSrc={Card1}
                >
                <div>
                    <h5 className="text-lg sm:text-xl font-semibold text-white">
                    Quad Bedroom [4 guests / 1 bedroom]
                    </h5>
                </div>
                <div className="flex items-center justify-between">
                    <p className="text-xl sm:text-2xl font-semi-bold sm:font-bold text-gray-100">₹ 1,800/person*<span className="text-sm sm:text-base ps-2 text-gray-400">[1 night]</span></p>
                    <Link to={"https://wa.me/918388837430?text=I'm%20inquiring%20about%20the%20homestay%20booking." } target="_blank" rel="noopener noreferrer"><Button color="success">Book now</Button></Link>
                </div>
            </Card>
            <Card
                className="max-w-xl"
                imgAlt="Apple Watch Series 7 in colors pink, silver, and black"
                imgSrc={Card2}
                >
                <div>
                    <h5 className="text-lg sm:text-xl font-semibold text-white">
                    Double Bedroom [2 guests / 1 bedroom]
                    </h5>
                </div>
                <div className="flex items-center justify-between">
                    <p className="text-xl sm:text-2xl font-semi-bold sm:font-bold text-gray-100">₹ 1,800/person*<span className="text-sm sm:text-base ps-2 text-gray-400">[1 night]</span></p>
                    <Link to={"https://wa.me/918388837430?text=I'm%20inquiring%20about%20the%20homestay%20booking." } target="_blank" rel="noopener noreferrer"><Button color="success">Book now</Button></Link>
                </div>
            </Card>
            <p className="text-sm sm:text-base text-gray-500">*Above tariffs will may change during festivals/ holidays/ off-season.</p>
            </div>
        </div>
        </>
    );
}