import { Breadcrumb, BreadcrumbItem } from "flowbite-react";
import { HiHome } from "react-icons/hi";

export default function Breadcrumbcomp() {
  return (
    <div className="breadcrumb1">
        <Breadcrumb aria-label="Solid background breadcrumb example" className="rounded bg-green-900 px-5 py-3">
            <BreadcrumbItem href="/" icon={HiHome}>
                Home
            </BreadcrumbItem>
            <BreadcrumbItem>Gallery</BreadcrumbItem>
        </Breadcrumb>
    </div>
  );
}
