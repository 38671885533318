import { Card } from "flowbite-react";

export default function Section3() {
    return(
        <>
        <div className="mt-12 sm:mt-24 text-start primary-color">
            <p className='text-4xl font-semibold text-white' id="offerings">Our Offerings</p>
            <div className="grid grid-cols-1 sm:grid-cols-3 mt-12 gap-5">
                <Card className="max-w-sm c-bg1">
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                    Delicious foods
                    </h5>
                    <p className="font-normal text-gray-100">
                    Start your day with a hearty breakfast featuring fluffy omelets made with farm-fresh eggs, accompanied by slices of juicy tomatoes and crispy cucumbers picked just moments before. For lunch and dinner, feast on delectable dishes bursting with flavor, from fragrant curries to vibrant stir-fries, all prepared with the freshly plucked garden vegetables.
                    </p>
                </Card>
                <Card className="max-w-sm c-bg2">
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                    Bonfire
                    </h5>
                    <p className="font-normal text-gray-100">
                    Whether you're seeking a romantic evening under the stars or a lively gathering with newfound friends, our bonfire nights promise to be a highlight of your stay. Join us as we create memories that will warm your heart long after the embers fade.
                    </p>
                </Card>
                <Card className="max-w-sm c-bg3">
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                    Barbeque
                    </h5>
                    <p className="font-normal text-gray-100">
                    Choose from an enticing selection of locally sourced meats, and garden-fresh vegetables, all expertly seasoned and grilled to perfection. Indulge in juicy kebabs, succulent steaks, and smoky grilled vegetables, accompanied by an assortment of delectable sauces and sides*.
                    </p>
                </Card>
                <Card className="max-w-sm c-bg4">
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                    WiFi
                    </h5>
                    <p className="font-normal text-gray-100">
                    Stay connected with complimentary WiFi during your stay. While we encourage guests to disconnect and immerse themselves in the natural beauty of Darjeeling, we understand the importance of staying connected with loved ones and accessing essential online resources.
                    </p>
                </Card>
                <Card className="max-w-sm c-bg5">
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                    Rental bike and cabs
                    </h5>
                    <p className="font-normal text-gray-100">
                    Explore Darjeeling at your own pace with our convenient rental bike and cab services. Whether you're eager to embark on a solo adventure or seeking the comfort of a guided tour, we've got you covered.
                    </p>
                </Card>
                <Card className="max-w-sm c-bg6">
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                    Local guide
                    </h5>
                    <p className="font-normal text-gray-100">
                    For those seeking adventure, here local guides offer thrilling trekking expeditions to off-the-beaten-path destinations, where you can witness breathtaking panoramas and encounter rare flora and fauna found only in the Himalayas. Whether you're a seasoned trekker or a novice explorer, our guides will ensure a safe and unforgettable journey through the pristine wilderness.
                    </p>
                </Card>
            </div>
        </div>
        </>
    );
}