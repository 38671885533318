import Section2Img from '../Assets/About.webp'

export default function Section2() {
    return(
        <>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-12 sm:mt-24">
            <div className="order-2 sm:order-1 primary-color text-start">
                <img className='rounded w-11/12' src={Section2Img} alt='Homestay' />
                <p className='text-base contents sm:hidden '><br/><br/>Indulge in the warm hospitality of our family-run establishment, where every guest is treated like a cherished friend. Wake up to the gentle melodies of chirping birds and the invigorating aroma of fresh mountain air. Our cozy accommodations are thoughtfully designed to provide comfort and relaxation after a day of exploration.<br/><br/></p>
                <p className='text-base contents sm:hidden'>Whether you're seeking a romantic getaway, a family retreat, or a solo adventure, Lamahatta Grove Homestay is your perfect home base in Darjeeling. Book your stay with us today and embark on a memorable journey amidst the tranquil beauty of the Himalayas.</p>
            </div>
            <div className="order-1 sm:order-2 text-start content-center primary-color">
                <p className='text-4xl pb-4 font-semibold text-white'>About Us</p>
                <p className='text-base'>Lamahatta Grove Homestay stands high on immense land rich in agricultural farming which produces its own organic vegetation for their guest the traditional way of farming carried on by generation. The orange Grove stands tall on the farm land with other seasonal fruit bearing plants.<br/><br/></p>
                <p className='text-base hidden sm:contents'>Indulge in the warm hospitality of our family-run establishment, where every guest is treated like a cherished friend. Wake up to the gentle melodies of chirping birds and the invigorating aroma of fresh mountain air. Our cozy accommodations are thoughtfully designed to provide comfort and relaxation after a day of exploration.<br/><br/></p>
                <p className='text-base hidden sm:contents'>Whether you're seeking a romantic getaway, a family retreat, or a solo adventure, Lamahatta Grove Homestay is your perfect home base in Darjeeling. Book your stay with us today and embark on a memorable journey amidst the tranquil beauty of the Himalayas.</p>
            </div>
        </div>
        </>
    );
}