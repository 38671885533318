import { Gallery } from "react-grid-gallery"
import Img1 from '../Assets/Gallery/img1.webp'
import Img2 from '../Assets/Gallery/img2.webp'
import Img3 from '../Assets/Gallery/img3.webp'
import Img4 from '../Assets/Gallery/img4.webp'
import Img5 from '../Assets/Gallery/img5.webp'
import Img6 from '../Assets/Gallery/img6.webp'
import Img7 from '../Assets/Gallery/img7.webp'
import Img8 from '../Assets/Gallery/img8.webp'
import Img9 from '../Assets/Gallery/img9.webp'
import Img10 from '../Assets/Gallery/img10.webp'
import Img11 from '../Assets/Gallery/img11.webp'
import Img12 from '../Assets/Gallery/img12.webp'
import Img13 from '../Assets/Gallery/img13.webp'
import Img14 from '../Assets/Gallery/img14.webp'
import Img15 from '../Assets/Gallery/img15.webp'
import Img16 from '../Assets/Gallery/img16.webp'
import Img17 from '../Assets/Gallery/img17.webp'
import Img18 from '../Assets/Gallery/img18.webp'
import Img19 from '../Assets/Gallery/img19.webp'
import Img20 from '../Assets/Gallery/img20.webp'
import Img21 from '../Assets/Gallery/img21.webp'
import Img22 from '../Assets/Gallery/img22.webp'
import Img23 from '../Assets/Gallery/img23.webp'

const images = [
    {
        src: Img22,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img23,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img16,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img17,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img8,
        width: 795,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img9,
        width: 795,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img10,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img11,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img12,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img4,
        width: 782,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Interior.</div>
         </div>
       )
    },
    {
        src: Img2,
        width: 782,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Dining area.</div>
         </div>
       )
    },
    {
        src: Img3,
        width: 782,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Dining area.</div>
         </div>
       )
    },
    {
       src: Img1,
       width: 782,
       height: 580,
       customOverlay: (
        <div className="custom-overlay__caption">
          <div>Balcony view.</div>
        </div>
      )
    },
    {
        src: Img5,
        width: 1280,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Balcony view.</div>
         </div>
       )
    },
    {
        src: Img6,
        width: 1280,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Balcony view.</div>
         </div>
       )
    },
    {
        src: Img7,
        width: 1280,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Balcony view.</div>
         </div>
       )
    },
    {
        src: Img13,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Balcony view.</div>
         </div>
       )
    },
    {
        src: Img15,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Balcony view.</div>
         </div>
       )
    },
    {
        src: Img20,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Common area.</div>
         </div>
       )
    },
    {
        src: Img21,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Common area.</div>
         </div>
       )
    },
    {
        src: Img14,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Night view.</div>
         </div>
       )
    },
    {
        src: Img18,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Bonfire area.</div>
         </div>
       )
    },
    {
        src: Img19,
        width: 580,
        height: 580,
        customOverlay: (
         <div className="custom-overlay__caption">
           <div>Garden area.</div>
         </div>
       )
    }
 ];
 
export default function GalleryComp() {
    return(
        <div className="my-6">
            <Gallery images={images} className="p-1" />
        </div>
    );
}