import HeroImage from '../Assets/Lamahatta-Grove-Homestay.webp'

export default function Section1() {
    return(
        <>
        <div className="grid grid-cols-1 sm:grid-cols-2 mt-10 gap-5">
            <div className="text-start content-center primary-color">
                <p className='text-lg sm:text-xl pb-4'>Plan Your Perfect Trip With</p>
                <p className='text-4xl sm:text-5xl pb-4 font-semibold text-white'>Lamahatta Grove Homestay</p>
                <p className='text-base sm:text-lg'>Escape to our cozy and favourite homestay in the picturesque hills of Darjeeling. Relax amidst lush greenery, where nature's beauty awaits.</p>
            </div>
            <div className="mt-5 sm:mt-0">
                <img className='rounded' src={HeroImage} alt='Homestay' />
            </div>
        </div>
        </>
    );
}