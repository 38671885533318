
import { Footer, FooterCopyright, FooterLink, FooterLinkGroup } from "flowbite-react";

export function FooterComp() {
  return (
    <Footer container className="bg-green-900">
      <FooterCopyright className="text-white" by="Lamahatta Grove Homestay" year={2024} />
      <FooterLinkGroup className="mt-3 sm:mt-0">
        <FooterLink className="text-gray-400" href="https://wa.me/919620633510?text=Hey,%20I%20need%20a%20website%20for%20my%20Business." target="_blank" rel="noopener noreferrer">Design & Developed with ❤️ by Rajeev Ranjan</FooterLink>
      </FooterLinkGroup>
    </Footer>
  );
}
