
import { Carousel } from "flowbite-react";
import { Rating, RatingStar } from "flowbite-react";
import { Avatar } from "flowbite-react";
import Phone from '../Assets/phone-call.png'
import Email from '../Assets/envelope.png'
import Location_pin from '../Assets/location.png'
import Whatsapp from '../Assets/whatsapp.png'
import Facebook from '../Assets/facebook.png'
import Insta from '../Assets/instagram.png'
import { Link } from "react-router-dom";

export function Section5() {
  return (
    <>
    <div className="mt-12 sm:mt-24 text-start">
        <p className="text-4xl font-semibold text-white">Testimonials & Ratings</p>
        <Rating className="mt-4">
            <RatingStar />
            <p className="ml-2 text-sm font-bold primary-color">5.00</p>
            <span className="mx-1.5 h-1 w-1 rounded-full bg-gray-100" />
            <a href="https://www.google.com/search?sca_esv=ec20631805f8be26&sca_upv=1&rlz=1C5CHFA_enIN1088IN1088&q=lamahatta+grove+homestay&source=lnms&uds=AMwkrPsg8Blao-B2ZO2mI_MCJeFeqdobriHV8J_OnWq-6uxhha3hQ0WpVERyUCzT87ZNhzIGhGAZt-pkq2TmG1IWVeuQ8Cd9jHs3utfOImHMQZgEhPKwScapK4ZXmQv6uQTTPzCT381-hVhyWdlYCbNAL_9NVz36I8fe22ni6FwUyItI_VELz9E_kLFnWpY2-m7gtDVs4_zFtHZhxDivjCVLjKtRR6GAWTWXOSXafOh7xXuLdfYQJ-WWhV9DEBOzTgp-KOtrFXAV96mZdj87300Jom2OVmXtB3FrOxSRbN2G0qR_vo5HjxPbqdKcbbQo2fkuEbUKBp_g&sa=X&ved=2ahUKEwiWzrrw38iFAxWxXmwGHffAAo4Q0pQJegQIBxAB&biw=1470&bih=747&dpr=2#" className="text-sm font-medium primary-color hover:text-cyan-400 underline hover:no-underline">
                52 Google reviews
            </a>
        </Rating>
    </div>
    <div className="mt-8 h-80 sm:h-60">
      <Carousel pauseOnHover className="bg-green-900 c-avatar rounded shadow-md">
        <Avatar placeholderInitials="DD" rounded>
            <div className="space-y-1 font-normal sm:font-medium">
                <p className="primary-color font-semibold mt-4">DEBASIS DUTTA</p>
                <div className="text-sm text-gray-200 px-1 sm:px-2">We visited the place a couple of days ago. We booked three rooms. The rooms and toilets were clean. The view of the hills and woods were awesome from the balconies. The behaviour and service of Mr. Jogender and his family was outstanding. Wi-Fi was operational. Any one can feel a homely atmosphere here. A must stay place.</div>
            </div>
        </Avatar>
        <Avatar placeholderInitials="PT" rounded>
            <div className="space-y-1 font-medium">
                <p className="primary-color font-semibold mt-4">Poushaly Talukdar</p>
                <div className="text-sm text-gray-200">Me and my mother recently went on a short weekend getaway to Lamahatta. We wanted a serene quiet room and this homestay was beyond perfect. The owner and his wife are some of the most welcoming and warm people I have come across in my life. They make you feel at home. Feed you the absolute freshest and purest stuff from their kitchen garden. Endless rounds of Darjeeling tea. They have their own orange orchard where you can pick oranges from the tree for yourself and take it back home. Will definitely visit again.</div>
            </div>
        </Avatar>
        <Avatar placeholderInitials="RK" rounded>
            <div className="space-y-1 font-medium">
                <p className="primary-color font-semibold mt-4">RAMASHIS KUNDU</p>
                <div className="text-sm text-gray-200">Very good experience, Well behaved staffs, owner is very polite, smiling face all the time. Very disciplined staffs and professional. We enjoyed a lot. We will definitely visit next time. <br/>Rooms: Neat and clean rooms also toilets very clean.<br/>Food & drinks: Organic foods very tasty and hygienic.</div>
            </div>
        </Avatar>
        <Avatar placeholderInitials="SG" rounded>
            <div className="space-y-1 font-medium">
                <p className="primary-color font-semibold mt-4">Snehangshu Ghosh</p>
                <div className="text-sm text-gray-200">Food quality is like bengali menu... we 5 person all have same reaction about food.... Room's are too good,  and hospitality is awesome... best homestay at Lamahata... I personally recommend this place to all of you to consider once in lifetime....</div>
            </div>
        </Avatar>
        <Avatar placeholderInitials="AB" rounded>
            <div className="space-y-1 font-medium">
                <p className="primary-color font-semibold mt-4">Aratrika Basu</p>
                <div className="text-sm text-gray-200">It was an amazing experience! The owner, Yogendra Pradhan is the nicest person ever. He is super friendly and attentive towards his guests. In fact his whole family is very welcoming and easygoing. The rooms are clean, spacious with an amazing view. The food is delicious and homemade! If you want to spend a day or two at somewhere serene and get detoxified this is the place for you. Would definitely recommend!!</div>
            </div>
        </Avatar>
        <Avatar placeholderInitials="AK" rounded>
            <div className="space-y-1 font-medium">
                <p className="primary-color font-semibold mt-4">Anamika Kulung</p>
                <div className="text-sm text-gray-200">We had a wedding at @lamahattagrove homestay and it went so smoothly,on top of that the food,well furnished and clean rooms,service which provided by Mr.Yogen Pradhan(Owner) & Mrs.Tripti Pradhan(Owner) was exceptionally good.In addition if you all wants to try glenburn tea Mr.Yogen Pradhan will definitely assist you on various ways.Please do recommend for whoever wants to go get the view of kanchenjunga.</div>
            </div>
        </Avatar>
      </Carousel>
    </div>
    <div className="my-12 sm:my-24 text-start">
        <p className="text-xl font-semibold primary-color">A complete tour of our homestay by <span className="text-2xl text-white">Jayanti, The Shining Bong</span></p>
        <div className="t-center mt-12">
            <iframe className="w-11/12 sm:w-7/12 h-60 sm:h-96 rounded" src="https://www.youtube-nocookie.com/embed/bIb1JaBxM1I?si=yRZjleJBZl7IhL7R" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
    </div>
    <div className="my-12 sm:my-24 text-start" id="contact">
        <p className="text-4xl font-semibold text-white">Reach Us</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mt-4">
                <div className="flex flext-row gap-4 f-ico mt-4">
                    <img src={Phone} alt="Phone" />
                    <p className="primary-color text-basic">+91-8388837430, 7583912412</p>
                </div>
                <div className="flex flext-row gap-4 f-ico mt-4">
                    <img src={Email} alt="Email" />
                    <p className="primary-color text-basic">yogendrapradhan06@gmail.com</p>
                </div>
                <div className="flex flext-row gap-4 f-ico mt-4">
                    <img src={Location_pin} alt="Address" />
                    <p className="primary-color text-basic">Lamahatta Grove Homestay, Lamahatta Busty, P.O - Lamahatta, Dist. - Darjeeling, Pin. 734213</p>
                </div>
                <div className="hidden sm:contents">
                    <p className="text-xl text-white mt-8">Social connect</p>
                    <div className="flex flext-row gap-8 f-ico mt-4">
                        <Link to={"https://wa.me/?text=I'm%20inquiring%20about%20the%20homestay%20booking."} target="_blank" rel="noopener noreferrer"><img src={Whatsapp} alt="Chat on WhatsApp" /></Link>
                        <Link to={"https://www.facebook.com/people/Lamahatta-GROVE-Homestay/100090706812015/?mibextid=ZbWKwL"} target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Find us on Facebook" /></Link>
                        <Link to={"https://www.instagram.com/lamahatta_grove_homestay"} target="_blank" rel="noopener noreferrer"><img src={Insta} alt="Find us on Instagram" /></Link>
                    </div>
                </div>
            </div>
            <div className="">
                <iframe className="rounded w-full h-60 sm:h-96" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14211.333232118759!2d88.3421534!3d27.0670091!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e42591cc607f75%3A0x48344700d3e4ab81!2sLAMAHATTA%20GROVE%20HOMESTAY!5e0!3m2!1sen!2sin!4v1713407135794!5m2!1sen!2sin" title="Reach us" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="contents sm:hidden">
                    <p className="text-xl text-white mt-8">Social connect</p>
                    <div className="flex flext-row gap-8 f-ico mt-4">
                        <Link to={"https://wa.me/?text=I'm%20inquiring%20about%20the%20homestay%20booking."} target="_blank" rel="noopener noreferrer"><img src={Whatsapp} alt="Chat on WhatsApp" /></Link>
                        <Link to={"https://www.facebook.com/people/Lamahatta-GROVE-Homestay/100090706812015/?mibextid=ZbWKwL"} target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Find us on Facebook" /></Link>
                        <Link to={"https://www.instagram.com/lamahatta_grove_homestay"} target="_blank" rel="noopener noreferrer"><img src={Insta} alt="Find us on Instagram" /></Link>
                    </div>
            </div>
        </div>
    </div>
    </>
  );
}
