import { Button, Navbar, NavbarBrand, NavbarCollapse, NavbarLink, NavbarToggle } from "flowbite-react";
import Logo from '../Assets/logo_png.png'
import { Link } from "react-router-dom";

export default function NavbarComp() {
  return (
    <Navbar fluid rounded>
      <NavbarBrand>
        <Link to={'/'}>
          <img className="w-32" src={Logo} alt="Lamahatta Grove Homestay, Darjeeling" />
        </Link>
      </NavbarBrand>
      <div className="flex md:order-2">
        <Link to={"https://wa.me/918388837430?text=I'm%20inquiring%20about%20the%20homestay%20booking." } target="_blank" rel="noopener noreferrer"><Button className="me-6" color="success">Book now</Button></Link>
        <NavbarToggle />
      </div>
      <NavbarCollapse>
        <NavbarLink><Link to={'/'}>Home</Link></NavbarLink>
        <NavbarLink ><Link to={"/gallery"}>Gallery</Link></NavbarLink>
        <NavbarLink href="#contact">Contact</NavbarLink>
      </NavbarCollapse>
    </Navbar>
  );
}
